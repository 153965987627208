import React, { useEffect } from 'react';
import { makeStyles, createStyles } from '@mui/styles';  // Ensure you import Theme from '@mui/material/styles'

import { Theme } from '@mui/material/styles';
import SiteTitle from './SiteTitleComponent';
import WhyUs from './Whyus';
import WhatSetsApart from './WhatSetsApart';
import ProductList from './ProductList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      /*  padding: theme.spacing(1), */
      color: theme.palette.primary.contrastText,
    },
    section: {
      marginTop: 70,
      marginBottom: theme.spacing(4),
    },
    card: {
      maxWidth: 300,
      height: 300,
      'margin-left': '5%',
      width: "90%",
      margin: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
    },
    media: {
      height: 0,
      paddingTop: '46.25%',
    },
    toolbar: theme.mixins.toolbar,
    learnMoreButton: {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
  })
);


interface LandingComponentProps {
  targetSection: string;
}

const LandingComponent: React.FC<LandingComponentProps> = ({ targetSection }) => {
  const classes = useStyles();

  useEffect(() => {
    var sectionId = 'home'
    console.log(targetSection);
    if (targetSection) {
    switch(targetSection){
      case 'HOME':sectionId = 'home'; break;
      case 'WHYUS': sectionId = 'whyus';break;
      case 'WHATAPART':sectionId='whatsetsapart';break;
      case 'PRODUCTLIST':sectionId='productlist';break;
      default: sectionId = 'home';
    }
  }

    var sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /*   const testimonialVideosData = [
      { id: 1, videoUrl: 'https://www.youtube.com/embed/exampleVideo1' },
      { id: 2, videoUrl: 'https://www.youtube.com/embed/exampleVideo2' },
      { id: 3, videoUrl: 'https://www.youtube.com/embed/exampleVideo3' },
    ]; */
  

  return (
    <div className={classes.root}>

      <section id="home" style={{paddingTop:'70px'}}> <SiteTitle /></section>
      <section id="whyus">    <WhyUs /></section>
      <section id="whatsetsapart">     <WhatSetsApart /></section>
      <section id="productlist">  <ProductList /></section>

      {/*   <section className={classes.section}>
          <Typography variant="h4" gutterBottom>
            Testimonial Videos
          </Typography>
          <Grid container spacing={3}>
            {testimonialVideosData.map((item) => (
              <Grid item xs={12} sm={4} md={4} key={item.id}>
                <iframe
                  title={`Testimonial Video ${item.id}`}
                  width="100%"
                  height="315"
                  src={item.videoUrl}
                  frameBorder="0"
                  allowFullScreen
                />
              </Grid>
            ))}
          </Grid>
        </section> */}
    </div>
  );
};

export default LandingComponent;

