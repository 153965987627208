// src/components/ContactUs.tsx
import React, { useState, ChangeEvent } from 'react';
import { makeStyles, createStyles } from '@mui/styles';  // Ensure you import Theme from '@mui/material/styles'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';


interface ContactForm {
  name: string;
  email: string;
  requestSummary: string;
  detailedDescription: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(3),
    },
    form: {
      width: '80%',
      maxWidth: 400,
      margin: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    submitButton: {
      marginTop: theme.spacing(2),
    },
    confirmationMessage: {
      marginTop: theme.spacing(2),
      color: 'green',
    },
  })
);

const ContactUs: React.FC = () => {
  const classes = useStyles();
  const [contactForm, setContactForm] = useState<ContactForm>({
    name: '',
    email: '',
    requestSummary: '',
    detailedDescription: '',
  });
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setContactForm((prevForm) => ({ ...prevForm, [name]: value } as ContactForm));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      if(!contactForm.email){
        contactForm.email = 'yandava@hotmail.com';
      }
      const response = await fetch('https://bybvutilities.azurewebsites.net/api/MailerUtil', {
      method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({'to':contactForm.email, 'subject':contactForm.requestSummary, 'text':contactForm.detailedDescription}),
      });

      if (response.ok) {
        setIsSubmitted(true);

        // Simulate clearing the form after a successful submission
        setTimeout(() => {
          setIsSubmitted(false);
          setContactForm({
            name: '',
            email: 'yandava@hotmail.com',
            requestSummary: '',
            detailedDescription: '',
          });
        }, 15000); // Assuming a 2-second delay for demonstration purposes
      } else {
        throw new Error(`Failed to submit form: ${response.status}`);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className={classes.root}>
      <h2>Contact Us</h2>
      <form className={classes.form} onSubmit={handleSubmit}>
        <TextField
          label="Name"
          id="name"
          name="name"
          value={contactForm.name}
          onChange={handleChange}
          variant="outlined"
          required
        />
        <TextField
          label="Email"
          id="email"
          name="email"
          value={contactForm.email}
          onChange={handleChange}
          variant="outlined"
          type="email"
          style={{ visibility: 'hidden' }}
          
        />
        <TextField
          label="Request Summary"
          id="requestSummary"
          name="requestSummary"
          value={contactForm.requestSummary}
          onChange={handleChange}
          variant="outlined"
          required
        />
        <TextField
          label="Detailed Description"
          id="detailedDescription"
          name="detailedDescription"
          value={contactForm.detailedDescription}
          onChange={handleChange}
          variant="outlined"
          multiline
          rows={4}
          required
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submitButton}
        >
          Submit
        </Button>
      </form>
      {isSubmitted && (
        <Typography variant="body1" className={classes.confirmationMessage}>
          Thank you for your submission! We will get back to you soon.
        </Typography>
      )}
    </div>
  );
};

export default ContactUs;
