import './../styles/Pagecomponent.css'; // Add a CSS file for styling
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import logoImageClr from './../images/bybv_colored_logo.png';

const WhyUs = () => {
  return (
    <Grid className="page-container" sx={{paddingTop:30}}>
      {/* Left Div with Summary and Description */}

      <Box sx={{ width: '100%', maxWidth: "60%", paddingLeft:30}} className="left-div">
            <Typography variant="h5" color='primary'>Why Choose Us?</Typography>
            <Typography variant="h6"  align="left"  sx={{ mb: 1, mt: { xs: 2, sm: 3 } }}>✍️ Handwriting Insights That Transform Lives</Typography>
            <Typography
        color="inherit"
        align="justify"
        fontFamily={'arial'}
        sx={{ mb: 4, mt: { xs: 1, sm: 1 } }}
        variant="body2"
      >Explore the art and science of Graphology to gain profound insights into your personality. Our expert evaluations go beyond surface analysis, delving into the nuances of your handwriting to uncover hidden strengths, challenges, and opportunities for personal and professional growth.

      </Typography>

      <Typography variant="h6"  align="left"  sx={{ mb: 1, mt: { xs: 2, sm: 3 } }}>🌈 Tailored GraphoTherapy Sessions</Typography>
            <Typography
        color="inherit"
        align="justify"
        fontFamily={'arial'}
        sx={{ mb: 4, mt: { xs: 1, sm: 1 } }}
        variant="body2"
      >Embark on a transformative journey with our personalized GraphoTherapy sessions. Our certified therapists work with individuals of all ages, helping them overcome obstacles, enhance their strengths, and achieve personal excellence. GraphoTherapy is not just a process; it's a pathway to self-discovery and empowerment.
      </Typography>


      <Typography variant="h6"  align="left"  sx={{ mb: 1, mt: { xs: 2, sm: 3 } }}>🚀 Professional Development at Every Stage</Typography>
            <Typography
        color="inherit"
        align="justify"
        fontFamily={'arial'}
        sx={{ mb: 4, mt: { xs: 1, sm: 1 } }}
        variant="body2"
      >Whether you're a student, a seasoned professional, or someone exploring new career paths, our GraphoTherapy and Graphology services cater to individuals at every stage of their professional journey. Empower yourself to excel in your chosen field by leveraging the insights derived from the unique combination of handwriting analysis and therapy.
      </Typography>
        </Box>
      
       

       

      {/* Right Div with Image */}
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '90%', maxWidth: "60%", padding:0, verticalAlign:'middle'}}  className="right-div" >
        <img
          src={logoImageClr} // Replace with your image source
          alt="Sample"
        />
      </Box>
    </Grid>
  );
};

export default WhyUs;
