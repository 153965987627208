// src/components/HeaderComponent.tsx
import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { SelectedComponent } from './DetailsPageComponent';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import BybvLogo from './../images/bybv_logo_small.png';
import Grid from '@mui/material/Grid';


interface HeaderComponentProps {
  setSelectedComponent: React.Dispatch<React.SetStateAction<SelectedComponent>>;
}

const HeaderComponent: React.FC<HeaderComponentProps> = ({ setSelectedComponent }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElSecond, setAnchorElSecond] = useState<null | HTMLElement>(null);
  const [anchorElProfessional, setAnchorElProfessional] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  

  const handleSecondClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElSecond(event.currentTarget);
  };

  const handleSecondMenuClose = () => {
    setAnchorElSecond(null);
  };

  const handleProfessionalClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElProfessional(event.currentTarget);
  };

  const handleProfessionalMenuClose = () => {
    setAnchorElProfessional(null);
  };

 /*  const handleMenuMouseOut=() => {
    handleMenuClose(); 
    handleSecondMenuClose();
  } */

  const handleComponentClick = (component: SelectedComponent) => {
    setSelectedComponent(component);
    handleMenuClose();
    handleSecondMenuClose();
    handleProfessionalMenuClose();
  };

  return (
    <div>
      <AppBar position="fixed">
      <Container id="hcContainer" maxWidth="xl">
        <Toolbar>
        
          <img src={BybvLogo} alt="Logo" height={40} width={40} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#"
            onClick={() => handleComponentClick(SelectedComponent.HOME)}
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              width: '50%',
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              padding:'20px'
            }}
          >
           Be Your Best Version
          </Typography>
          <Grid container justifyContent="flex-end">
          <div style={{display: 'flex', float:'right'}}>
            <Button color="inherit" onClick={handleClick}>Home</Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
               <MenuItem onClick={() => handleComponentClick(SelectedComponent.HOME)}>
               Home
              </MenuItem>
              <MenuItem onClick={() => handleComponentClick(SelectedComponent.WHYUS)}>
               Why Us
              </MenuItem>
              <MenuItem onClick={() => handleComponentClick(SelectedComponent.WHATAPART)}>
               What Sets Us Apart
              </MenuItem>
            {/*   <MenuItem onClick={() => handleComponentClick(SelectedComponent.WHATWEDO)}>
                How does it Work
              </MenuItem> */}
              <MenuItem onClick={() => handleComponentClick(SelectedComponent.PRODUCTLIST)}>
                Services
              </MenuItem>
            </Menu>
            
          </div>
        
          <div style={{paddingLeft:40}}>
            <Button color="inherit" onClick={handleSecondClick}>About</Button>
            <Menu
              id="simple-menu-2"
              anchorEl={anchorElSecond}
              open={Boolean(anchorElSecond)}
              onClose={handleSecondMenuClose}
            >
              <MenuItem onClick={() => handleComponentClick(SelectedComponent.GRAPHOTHERAPY)}>
                Grapho Therapy
              </MenuItem>
              <MenuItem onClick={() => handleComponentClick(SelectedComponent.WHOAREWE)}>
                Who are we?
              </MenuItem>
              <MenuItem onClick={() => handleComponentClick(SelectedComponent.WHEREAREWE)}>
                Where are we
              </MenuItem>
            </Menu>
          </div>

          <div style={{paddingLeft:40}}>
            <Button color="inherit" onClick={handleProfessionalClick}>Professional</Button>
            <Menu
              id="simple-menu-2"
              anchorEl={anchorElProfessional}
              open={Boolean(anchorElProfessional)}
              onClose={handleProfessionalMenuClose}
            >
              <MenuItem onClick={() => handleComponentClick(SelectedComponent.SEAFARER)}>
              Seafarer
              </MenuItem>
            </Menu>
          </div>
          <div style={{paddingLeft:40}}>
            <Button color="inherit" onClick={() =>handleComponentClick(SelectedComponent.CONTACTUS)}>Contact Us</Button>
            </div>
          </Grid>
        </Toolbar>
        </Container>
      </AppBar>
      {/* Render the selected component in DetailsPageComponent */}
     {/* <DetailsPageComponent selectedComponent={SelectedComponent.NONE} /> */}
    
    </div>
  );
};

export default HeaderComponent;
