import Typography from '@mui/material/Typography';
import BybvLogo from './../images/bybv_colored_logo.png';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

export default function Seafarer() {
  return (
    <Grid className="page-container" sx={{ paddingTop: 0 }}>
      {/* Left Div with image */}
      <Box sx={{ display: 'flex', justifyContent: 'center', maxWidth: "40%", verticalAlign: 'middle' }} className="right-div" >
        <img
          src={BybvLogo}
          alt="Sample"
        />
      </Box>
      {/* Right Div with Summary and Description */}

      <Box sx={{ width: '100%', maxWidth: "60%", paddingRight: 20 }} className="left-div">
        <Typography variant="h4" color='primary'>Steer Your Course Towards Wellbeing </Typography>
        <Typography variant="h5" align="left" sx={{ mb: 1, mt: { xs: 2, sm: 3 } }} color='secondary'>Graphotherapy for Seafarers</Typography>
        <Typography
          color="white"
          align="justify"
          fontFamily={'arial'}
          sx={{ mb: 4, mt: { xs: 1, sm: 1 } }}
          variant="body1"
        > Uncharted waters, demanding schedules, and extended periods away from home - life at sea presents unique challenges to your emotional and mental health. As a seafarer, you navigate stress, anxiety, and sometimes feelings of isolation, impacting your well-being and performance.

          But there's a beacon of hope: graphotherapy.

          Be Your Best Version offers a comprehensive suite of graphotherapy services designed specifically for seafarers, helping you:
          <ul>
            <li>
              <Typography variant="body2">
                Chart your emotional course: Through graphology and handwriting analysis, we unlock insights into your personality, communication style, and hidden emotions.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                Navigate stress and anxiety: Our signature review identifies stress triggers and guides you towards managing them effectively. **This is your personalized roadmap to calmer seas.**
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
              Gain personalized direction: Individual assessments map your unique needs and challenges, forming the foundation for a personalized graphotherapy program.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
              Embrace a steady rhythm: Our monthly program provides a structured approach with personalized schedules, tailoring exercises to your busy routine. Find emotional balance amidst the waves.
               </Typography>
            </li>
            <li>
              <Typography variant="body2">
              Seek safe harbor: Private counseling sessions offer a confidential space to delve deeper into emotional concerns and develop coping mechanisms.
              </Typography>
            </li>
          </ul>
        </Typography>


        <Typography variant="h5" align="left" sx={{ mb: 1, mt: { xs: 2, sm: 3 } }} color='secondary'>Benefits that anchor your wellbeing</Typography>
        <Typography
          color="white"
          align="justify"
          fontFamily={'arial'}
          sx={{ mb: 4, mt: { xs: 1, sm: 1 } }}
          variant="body1"
        >    <ul>
            <li>
              <Typography variant="body2">
              Reduced stress and anxiety: By identifying and releasing negative emotions through writing exercises, you find calmer waters and navigate challenges with greater resilience. Imagine feeling centered and in control, even during storms.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
              Improved communication and relationships: Gaining insight into your communication style fosters stronger connections with colleagues and loved ones back home. Feel closer, even when miles apart.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
              Managed homesickness and isolation: Expressing emotions related to being away from home can alleviate feelings of loneliness and isolation. Find solace and connection, even at sea.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
              Streamlined emotional and behavioral health: Graphotherapy complements traditional therapy or counseling, offering additional tools for managing mental well-being. Chart a course towards lasting emotional balance.
               </Typography>
            </li>
            <li>
              <Typography variant="body2">
              Enhanced self-awareness: Uncover hidden thoughts and feelings, leading to better self-understanding and personal growth. Discover your inner strength and resilience.
              </Typography>
            </li>
          </ul>
        </Typography>


        <Typography
          color="secondary"
          align="justify"
          fontFamily={'arial'}
          sx={{ mb: 4, mt: { xs: 1, sm: 1 } }}
          variant="body1"
        >  Bes your Best Version is your trusted partner on this journey. We offer:  <ul>
            <li>
              <Typography variant="body2"  color="white">
              <b>Remote services: </b>Connect with qualified graphotherapists online from anywhere in the world, even at sea.
              </Typography>
            </li>
            <li>
              <Typography variant="body2" color="white">
              <b>Tailored approach: </b>We design programs and exercises specifically for your needs and challenges.
              </Typography>
            </li>
            <li>
              <Typography variant="body2" color="white">
              <b>Complete discretion:</b> Your privacy is our top priority. All sessions and information remain confidential.
              </Typography>
            </li>
                    </ul>
        </Typography>
      </Box>
    </Grid>

  );
}