import * as React from 'react';
import Typography from '@mui/material/Typography';
import BybvLogo from './../images/bybv_colored_logo.png';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

export default function GraphoTherapy() {
  return (
    <Grid className="page-container" sx={{ paddingTop: 20 }}>
  {/* Left Div with image */}
      <Box sx={{ display: 'flex', justifyContent: 'center', maxWidth: "40%", verticalAlign: 'middle' }} className="right-div" >
        <img
          src={BybvLogo}
          alt="Sample"
        />
      </Box>
      {/* Right Div with Summary and Description */}

      <Box sx={{ width: '100%', maxWidth: "60%", paddingRight: 20 }} className="left-div">
        <Typography variant="h4" color='primary'>Unwind the Scroll of Your Soul</Typography>
        <Typography variant="h5" align="left" sx={{ mb: 1, mt: { xs: 2, sm: 3 } }} color='secondary'>Discover GraphoTherapy, Where Handwriting Holds the Key to Your Potential</Typography>
        <Typography
          color="white"
          align="justify"
          fontFamily={'arial'}
          sx={{ mb: 4, mt: { xs: 1, sm: 1 } }}
          variant="body1"
        > Imagine: unlocking hidden strengths, understanding your deepest motivations, and rewriting your own narrative – all through the simple act of putting pen to paper. GraphoTherapy isn't just about improving your handwritting; it's a journey of self-discovery, a roadmap to maximizing your potential at any age and any stage of your professional journey.
        Think of your handwriting as a secret language, a whisper of your subconscious, etched onto the page. GraphoTherapy, a unique blend of graphology analysis and therapeutic exercises, deciphers this code. Through a personalized evaluation, we unlock the meaning hidden within your strokes and slants, revealing your emotional tapestry, cognitive landscape, and innate talents.
        Embrace the wisdom of Leonardo da Vinci: "Painting is the gateway to the soul." Just as a brush paints on canvas, your pen paints a portrait of your inner self. Our trained graphologists act as skilled interpreters, translating the nuances of your strokes into a symphony of self-understanding.
        But GraphoTherapy isn't just about looking inward; it's about stepping outward, empowered. With targeted exercises and personalized exercises, you transform your handwriting into a tool for positive change. Imagine, with each deliberate curve and loop, you reshape your emotional patterns, refine your communication, and boost your confidence.
        Remember the words of Ralph Waldo Emerson: "Do not go where the path may lead, go instead where there is no path and leave a trail." GraphoTherapy doesn't follow pre-paved paths. We empower you to create your own masterpiece, rewriting limiting beliefs and paving the way for a more fulfilling life.
        Whether you're a driven student seeking peak focus, a seasoned professional navigating a career transition, or simply an individual yearning to live with greater authenticity, GraphoTherapy offers a transformative key.
        Ready to embark on this inspiring journey? Take the first step: schedule your personalized graphology evaluation today. Discover the stories your handwriting tells, ignite your inner potential, and write the most inspiring chapter of your life yet.
        Remember, the pen truly is mightier than the sword. Let GraphoTheray 
        </Typography>
      </Box>
    </Grid>

  );
}