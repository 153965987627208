import React, { useState } from 'react';
import HeaderComponent from './components/HeaderComponent';
import DetailsPageComponent, { SelectedComponent } from './components/DetailsPageComponent';
import CopyrightFooterComponent from './components/CopyrightFooterComponent';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.css'; // Import the CSS file for styling


const theme = createTheme();

const App: React.FC = () => {
  const [selectedComponent, setSelectedComponent] = useState<SelectedComponent>(SelectedComponent.HOME);

  return (
    <ThemeProvider theme={theme}>
    <div className="app-container">
      <HeaderComponent setSelectedComponent={setSelectedComponent} />
      <DetailsPageComponent selectedComponent={selectedComponent} />
      <CopyrightFooterComponent />
    </div>
    </ThemeProvider>
  );
};

export default App;
