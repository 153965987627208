import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SiteTitleLayout from './SiteTitleLayout';
import BybvLogo from './../images/default_test_logo.png';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import meadowsImage from './../images/lush_green_meadows.jpeg';


export default function SiteTitle() {
  return (
    <SiteTitleLayout
      sxBackground={{
        backgroundImage: `url(${meadowsImage})`,
        backgroundColor: '#1B2646', // Average color of the background image.
        backgroundPosition: 'center',
        paddingTop:60,
      }}
    >
      <Grid style={{ display: 'flex', position: 'relative', backgroundColor: '', paddingTop: 60 }} >
        <Box sx={{ display: 'flex', justifyContent: 'center', maxWidth: "40%", marginTop: '70px', verticalAlign: 'middle' }} >
          <img src={BybvLogo} alt="Logo" style={{ display: 'flex', width:'100%', height:'100%' }} />
        </Box>
        <div style={{ flex: 1, padding: '50px', color: '#ffffff' }}>

          <Box sx={{ width: '100%', maxWidth: '100%', marginTop: '70px' }}>
            <Typography variant="h3" color='primary' fontFamily={'cursive'} align="left" >Reimagine Your Handwriting, Reimagine Yourself</Typography>
            <Typography variant="h6" align="left" sx={{ mb: 1, mt: { xs: 2, sm: 3 } }}>Do you ever wonder what lies beneath the surface of your handwriting?</Typography>
            <Typography
              color="inherit"
              align="justify"
              fontFamily={'arial'}
              sx={{ mb: 4, mt: { xs: 1, sm: 1 } }}
              variant="body1"
            >
              What secret whispers of potential and untapped reserves dance within the strokes and curves? Be Your Best Version, invites you to embark on a fascinating journey of self-discovery, where the canvas isn't an easel but the page, and the brush isn't a tool but your very penmanship. <br /><br />
              Imagine, if you will, a universe contained within your fingertips. A swirling nebula of emotions, a constellation of strengths, and hidden galaxies of untapped potential. All waiting to be charted, not on a cosmic map, but on the very canvas of your own handwriting.
            </Typography>
          </Box>
        </div>
      </Grid>
      <div>
        <Button
          color="secondary"
          variant="contained"
          size="large"
          component="a"
          href="#productlist"
          sx={{ minWidth: 200 }}
        >
          Start your Journey
        </Button>
        <Typography variant="body2" color="inherit" sx={{ mt: 2 }}>
          Discover the experience
        </Typography>
      </div>
    </SiteTitleLayout>
  );
}