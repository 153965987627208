// src/components/DetailsPageComponent.tsx
import React from 'react';
import ContactUs from './ContactUs';
import LandingComponent from './LandingComponent';
/* import WhyUs from './Whyus'; */
import GraphoTherapy from './GraphoTherapy';
import Seafarer from './Seafarer';

export enum SelectedComponent {
  NONE,
  COMPONENT1,
  COMPONENT2,
  CONTACTUS,
  WHYUS,
  WHATAPART,
  WHOAREWE,
  WHEREAREWE,
  WHATWEDO,
  HOME,
  PRODUCTLIST,
  GRAPHOTHERAPY,
  SEAFARER
}

interface DetailsPageProps {
  selectedComponent: SelectedComponent;
}

const DetailsPageComponent: React.FC<DetailsPageProps> = ({ selectedComponent }) => {
  return (
    <div>
      {selectedComponent === SelectedComponent.HOME && <div style={{ marginTop:'75px'}}><LandingComponent targetSection='HOME'/></div>}
      {selectedComponent === SelectedComponent.WHYUS && <div style={{ marginTop:'75px'}}><LandingComponent targetSection='WHYUS'/></div>}
      {selectedComponent === SelectedComponent.WHATAPART && <div style={{ marginTop:'75px'}}><LandingComponent targetSection='WHATAPART'/></div>}
      {selectedComponent === SelectedComponent.WHOAREWE && <div style={{ marginTop:'75px'}}>WHO ARE WE</div>} 
      {selectedComponent === SelectedComponent.WHEREAREWE && <div style={{ marginTop:'75px'}}>WHERE ARE WE</div>} 
      {selectedComponent === SelectedComponent.PRODUCTLIST && <div style={{ marginTop:'75px'}}><LandingComponent targetSection='PRODUCTLIST'/></div>} 
      {selectedComponent === SelectedComponent.COMPONENT2 && <div style={{ marginTop:'75px'}}>Content for Component 2</div>}
      {selectedComponent === SelectedComponent.CONTACTUS && <div style={{ marginTop:'75px'}}><ContactUs /></div>}
      {selectedComponent === SelectedComponent.GRAPHOTHERAPY && <div style={{ marginTop:'75px'}}><GraphoTherapy /></div>}
      {selectedComponent === SelectedComponent.SEAFARER && <div style={{ marginTop:'75px'}}><Seafarer /></div>}
    </div>
  );
};

export default DetailsPageComponent;
