import React from 'react';
import {
    Grid,
    Card,
    CardContent,
    CardHeader,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    IconButton,
    Icon,
    Paper,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import CheckBoxIcon from '@mui/icons-material/CheckCircleOutline';
import InfoIcon from '@mui/icons-material/Info';

interface Feature {
    name: string;
    available: boolean;
}

interface ProductData {
    product: string;
    subheading: string;
    features: Feature[];
}

const featuresData: ProductData[] = [
    {
        product: 'Exploration',
        subheading: '30-Day Journey to Well-being',
        features: [
            { name: 'Analyze Handwriting', available: true },
            { name: 'Pattern Suggestions', available: true },
            { name: 'Personal Connect - 1 hr/week', available: true },
            { name: 'Everyday Evaluation - 30 Days', available: true },
            { name: 'Practice Book', available: true },
            { name: '10 Day special gift', available: true },
            { name: '20 Day special gift', available: true },
            { name: 'On-demand consultations', available: false },
            { name: 'On-demand correction review', available: false },
            { name: 'On-demand progress review', available: false },
            { name: 'Personalized Interview preparation session', available: false },

        ],
    },
    {
        product: 'Achievement',
        subheading: `Navigate Life's Transitions`,
        features: [
            { name: 'Analyze Handwriting', available: true },
            { name: 'Pattern Suggestions', available: true },
            { name: 'Personal Connect - 1 hr/week', available: true },
            { name: 'Everyday Evaluation - 30 Days', available: true },
            { name: 'Practice Book', available: true },
            { name: '10 Day special gift', available: true },
            { name: '20 Day special gift', available: true },
            { name: 'On-demand consultations', available: true },
            { name: 'On-demand correction review', available: false },
            { name: 'On-demand progress review', available: false },
            { name: 'Personalized Interview preparation session', available: false },

        ],
    },
    {
        product: 'Transformation',
        subheading: 'Simple to use and highly effective',
        features: [
            { name: 'Analyze Handwriting', available: true },
            { name: 'Pattern Suggestions', available: true },
            { name: 'Personal Connect - 1 hr/week', available: true },
            { name: 'Everyday Evaluation - 30 Days', available: true },
            { name: 'Practice Book', available: true },
            { name: '10 Day special gift', available: true },
            { name: '20 Day special gift', available: true },
            { name: 'On-demand consultations', available: true },
            { name: 'On-demand correction review', available: true },
            { name: 'On-demand progress review', available: true },
            { name: 'Personalized Interview preparation session', available: true },
        ],
    },
];
const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        padding: theme.spacing(2),
        backgroundColor: '#ffe082', // Distinct background color
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        marginRight: theme.spacing(2),
    },
}));

const FeatureCard = ({ product, subheading, features }: ProductData) => {
    return (
        <Card
            sx={{
                borderRadius: 15, // Rounded corners
                minWidth: 200,
                minHeight: 350,
                padding: 0, // Padding around card content
                '& .MuiCardHeader-root': {
                    backgroundColor: 'lightblue', // Header background color
                },
                '& .MuiCardContent-root': {
                    backgroundColor: 'white', // Features section background color
                },
            }}
        >
            <CardHeader
                title={product}
                subheader={subheading}
            />
            <CardContent>
                <List sx={{
                    p: 0, // Remove default padding from the List
                    '& .MuiListItem-root': {
                        padding: 0.5, // Reduce padding for individual feature items
                    },
                }}>
                    {features.map((feature) => (
                        <ListItem key={feature.name} sx={{ paddingLeft: 5 }}>
                            <ListItemIcon>
                                {feature.available ? (
                                    <IconButton edge="end" aria-label="Available">
                                        <CheckBoxIcon sx={{ color: 'green' }} />
                                    </IconButton>
                                ) : (
                                    /*  <Checkbox edge="end" checked={false} // Use for styling
                                       sx={{ display: 'none' }}
                                     /> */
                                    <IconButton edge="end" aria-label="Unavailable">
                                        <Icon />
                                    </IconButton>
                                )}
                            </ListItemIcon>
                            <Typography variant="body2">{feature.name}</Typography>
                        </ListItem>
                    ))}
                </List>
            </CardContent>
        </Card>
    );
};


const ProductsPage = () => {
    const classes = useStyles();
    return (
        <Grid container spacing={2} sx={{ justifyContent: 'center', mt: 3, gap: 5 }}>
            {featuresData.map((data) => (
                <Grid item key={data.product} xs={6} sm={4} md={3}  >
                    <FeatureCard {...data} />
                </Grid>
            ))}
            <Paper elevation={3} className={classes.paper} style={{ marginBottom: '20px' }}>
                <InfoIcon color="primary" className={classes.icon} />
                <Typography variant="body1">
                    Contact our administrative staff for more details at +91 953 345 7171
                </Typography>
            </Paper>
        </Grid>
    );
};

export default ProductsPage;
