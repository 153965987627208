import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';  // Ensure you import Theme from '@mui/material/styles'
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      padding: theme.spacing(2),
      position: 'fixed',
      bottom: 0,
      width: '100%',
    },
    socialIcons: {
      marginRight: theme.spacing(1),
      color: theme.palette.primary.contrastText,
    },
  })
);

const CopyrightFooterComponent: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <Grid container justifyContent="center" alignItems="center"> {/* Added alignItems: 'center' */}
        <Grid item xs={12}> {/* Added xs={12} to span full width on smaller screens */}
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}> {/* Added alignItems: 'center' */}
            <Typography variant="body2" color="inherit">
              © 2024 Be Your Best Version. All rights reserved.
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <IconButton
            className={classes.socialIcons}
            component="a"
            href="https://www.facebook.com/fbhandle"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            className={classes.socialIcons}
            component="a"
            href="https://twitter.com/@"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            className={classes.socialIcons}
            component="a"
            href="https://wa.me/919533457171"
            target="_blank"
            rel="noopener noreferrer"
          >
            <WhatsAppIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            className={classes.socialIcons}
            component="a"
            href="https://www.linkedin.com/in/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default CopyrightFooterComponent;
