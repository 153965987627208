import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

/* import useStyles from '../styles/CommonPageComponentStyles'; */
import Box from '@mui/material/Box';
import logowriting from './../images/writing_review.jpeg';


const WhatSetsApart = () => {
 /*  const classes = useStyles(); */

  return (
    <Grid className="page-container" sx={{ paddingTop: 10 }}>
      {/* Left Div with image */}
      <Box sx={{ display: 'flex', justifyContent: 'center',  maxWidth: "40%", verticalAlign: 'middle' , height:'70%', width:'70%' }} className="right-div" >
        <img
          src={logowriting} // Replace with your image source
          alt="Sample"
        />
      </Box>
      {/* Right Div with Summary and Description */}

      <Box sx={{ width: '100%', maxWidth: "60%", paddingRight: 20 }} className="left-div">
        <Typography variant="h5" color='primary'>What Sets Us Apart</Typography>
        <Typography variant="h6" align="left" sx={{ mb: 1, mt: { xs: 2, sm: 3 } }}>🔍 Precision and Expertise</Typography>
        <Typography
          color="inherit"
          align="justify"
          fontFamily={'arial'}
          sx={{ mb: 4, mt: { xs: 1, sm: 1 } }}
          variant="body2"
        > Our team comprises seasoned Graphologists and certified therapists who bring a wealth of experience to the table. With a keen eye for detail and a deep understanding of human behavior, we provide accurate assessments and effective therapeutic interventions.

        </Typography>

        <Typography variant="h6" align="left" sx={{ mb: 1, mt: { xs: 2, sm: 3 } }}>🌐 Global Perspectives, Timeless Wisdom</Typography>
        <Typography
          color="inherit"
          align="justify"
          fontFamily={'arial'}
          sx={{ mb: 4, mt: { xs: 1, sm: 1 } }}
          variant="body2"
        >Drawing inspiration from the wisdom of great philosophers, we believe in the limitless potential of the human mind and the transformative power of self-awareness. As Aristotle once said, "Knowing yourself is the beginning of all wisdom." We guide you on a journey of self-discovery, drawing on the timeless insights of philosophers who recognized the profound connection between the mind and handwriting.
        </Typography>


        <Typography variant="h6" align="left" sx={{ mb: 1, mt: { xs: 2, sm: 3 } }}>🤝 Confidentiality and Trust</Typography>
        <Typography
          color="inherit"
          align="justify"
          fontFamily={'arial'}
          sx={{ mb: 4, mt: { xs: 1, sm: 1 } }}
          variant="body2"
        >Your privacy and trust are our top priorities. Rest assured that all interactions and assessments are handled with the utmost confidentiality. Our goal is to create a safe space where you can explore, grow, and unleash your true potential.
        </Typography>
      </Box>
    </Grid>
  );
};

export default WhatSetsApart;
